var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AptiveIcon } from '@aptive-env/storybook';
import { fetchUserAvatar } from 'services/calendar';
import AsyncRenderOnScroll from 'components/shared/AsyncRenderOnScroll';
import LoadingGif from 'assets/image/loading.gif';
var Header = function (_a) {
    var selectedEmployee = _a.selectedEmployee, handleClose = _a.handleClose, office = _a.office;
    var getAge = function (date) {
        var today = new Date();
        var birthDate = new Date(date);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    };
    var getJoinDate = function (date) {
        var joinDate = new Date(date);
        return joinDate.toLocaleDateString('en-US', {
            month: 'short',
            year: 'numeric',
        });
    };
    var renderEmployeeDefaultAvatar = function (employee) {
        return (_jsx("div", __assign({ className: "w-16 h-16 rounded-full bg-gray-200 flex items-center justify-center" }, { children: employee.name[0] })));
    };
    var renderEmployeeLoadingAvatar = function (employee) {
        return (_jsx("div", __assign({ className: "w-16 h-16 rounded-full bg-gray-200 flex items-center justify-center" }, { children: _jsx("img", { src: LoadingGif, alt: employee.name[0], className: "w-8" }) })));
    };
    var renderEmployeeAvatar = function (employee) {
        if (employee.external_id) {
            return (_jsx(AsyncRenderOnScroll, { loadingComponent: renderEmployeeLoadingAvatar(employee), fetchData: function () { return __awaiter(void 0, void 0, void 0, function () {
                    var response;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, fetchUserAvatar(employee.external_id, { useCache: true })];
                            case 1:
                                response = _a.sent();
                                return [2 /*return*/, response.result.avatarBase64
                                        ? "data:image/png;base64,".concat(response.result.avatarBase64)
                                        : null];
                        }
                    });
                }); }, render: function (data) {
                    if (!data) {
                        return renderEmployeeDefaultAvatar(employee);
                    }
                    return _jsx("img", { className: "w-16 h-16 rounded-full", src: data, alt: "" });
                } }));
        }
        return renderEmployeeDefaultAvatar(employee);
    };
    var renderEmployeeProfile = function (employee) {
        return (_jsxs("div", __assign({ className: "flex flex-col gap-2 mt-2" }, { children: [_jsxs("div", __assign({ className: "flex gap-10 items-center" }, { children: [_jsx("div", __assign({ className: "font-semibold" }, { children: employee.name })), _jsxs("div", __assign({ className: "flex gap-1" }, { children: [_jsx(AptiveIcon, { className: "w-4 h-4 stroke-emerald-500 fill-none", icon: "checkCircle" }), _jsx("span", __assign({ className: "text-xs text-emerald-500" }, { children: "Active" }))] }))] })), _jsxs("div", __assign({ className: "text-xs text-gray-600 font-normal" }, { children: ["Service Pro \u2022 Age ", getAge(selectedEmployee === null || selectedEmployee === void 0 ? void 0 : selectedEmployee.date_of_birth), " \u2022 ", office === null || office === void 0 ? void 0 : office.name, ", ", office === null || office === void 0 ? void 0 : office.state] })), _jsx("div", __assign({ className: "text-xs text-gray-900 text-sm cursor-pointer" }, { children: "View full profile" }))] })));
    };
    var renderEmployeeInfo = function (employee) {
        return (_jsxs("div", __assign({ className: "flex gap-4 px-4" }, { children: [_jsxs("div", __assign({ className: "flex items-center gap-1" }, { children: [_jsx(AptiveIcon, { className: "w-6 h-6 stroke-[#9CA3AF] fill-none", icon: "calendar" }), _jsxs("span", __assign({ className: "text-gray-600 text-xs" }, { children: ["Joined ", getJoinDate(selectedEmployee === null || selectedEmployee === void 0 ? void 0 : selectedEmployee.date_of_hire)] }))] })), _jsxs("div", __assign({ className: "flex items-center gap-1" }, { children: [_jsx(AptiveIcon, { className: "w-6 h-6 stroke-[#9CA3AF] fill-none", icon: "userGroup" }), _jsx("span", __assign({ className: "text-gray-600 text-xs" }, { children: office === null || office === void 0 ? void 0 : office.name }))] }))] })));
    };
    return (_jsx("div", { children: _jsxs("div", __assign({ className: "flex flex-col h-[163px] bg-gray-50 border-b border-gray-200" }, { children: [_jsxs("div", __assign({ className: "flex justify-between" }, { children: [_jsxs("div", __assign({ className: "flex p-6 gap-6" }, { children: [renderEmployeeAvatar(selectedEmployee), renderEmployeeProfile(selectedEmployee)] })), _jsx(AptiveIcon, { icon: "x", className: "w-6 h-6 stroke-[#3D3D3D] fill-none cursor-pointer mt-4 mr-4", onClick: handleClose })] })), _jsx("div", __assign({ className: "" }, { children: renderEmployeeInfo(selectedEmployee) }))] })) }));
};
export default Header;
