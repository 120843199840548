var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { AptiveIcon } from '@aptive-env/storybook';
import { OfficeModal } from 'components/OfficeModal';
export var PlanningOfficeSelect = function (_a) {
    var regions = _a.regions, office = _a.office, onChangeOffice = _a.onChangeOffice, setIsUpdated = _a.setIsUpdated, appContext = _a.appContext;
    var _b = useState(false), isShowingModal = _b[0], setIsShowingModal = _b[1];
    var handleSelectOffice = function (data) {
        onChangeOffice(data);
        setIsShowingModal(false);
        setIsUpdated(true);
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "p-4 text-gray-600 flex items-center justify-between" }, { children: [_jsxs("div", __assign({ className: "flex flex-col gap-1" }, { children: [_jsxs("div", __assign({ className: "text-xs text-gray-500 font-medium leading-4" }, { children: ["Region: ", "".concat(office ? office.region : 'N/A')] })), office && (_jsx("div", __assign({ className: "text-lg font-light leading-6 text-text-default" }, { children: office.name })))] })), _jsxs("div", __assign({ className: "flex items-center justify-center h-[40px] cursor-pointer", onClick: function () { return setIsShowingModal(true); } }, { children: [_jsx(AptiveIcon, { className: "w-[24px] h-[24px] stroke-gray-600 fill-none", icon: "globeAlt" }), _jsxs("div", __assign({ className: "w-[16px] h-[24px] flex flex-col items-center relative" }, { children: [_jsx(AptiveIcon, { className: "w-[16px] h-[12px] stroke-gray-600 fill-none absolute bottom-2.5", icon: "chevronUp" }), _jsx(AptiveIcon, { className: "w-[16px] h-[12px] stroke-gray-600 fill-none absolute top-2.5", icon: "chevronDown" })] }))] }))] })), office && (_jsx(OfficeModal, { className: "w-[658px] min-h-[599px]", open: isShowingModal, regions: regions, selectedOffice: office, center: office.location, onSelectOffice: handleSelectOffice, onClose: function () { return setIsShowingModal(false); }, appContext: appContext }))] }));
};
