var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useEffect } from 'react';
import { AptiveIcon, Button } from '@aptive-env/storybook';
var ReassignConfirmModal = function (_a) {
    var onClose = _a.onClose, onReassign = _a.onReassign, name = _a.name;
    var modalRef = useRef(null);
    useEffect(function () {
        var listener = function (event) {
            // Do nothing if clicking ref's element or descendent elements
            var el = modalRef === null || modalRef === void 0 ? void 0 : modalRef.current;
            if (!el || el.contains(event.target)) {
                return;
            }
            onClose();
        };
        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);
        return function () {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, [modalRef, onClose]);
    return (_jsxs("div", __assign({ className: "absolute translate-x-[-50%] left-1/2 top-[-20px] flex flex-col gap-3 bg-white z-50 p-[22px] shadow-[0px_3px_8px_0px_rgba(87,102,117,0.15)] rounded-lg w-[340px]", ref: modalRef }, { children: [_jsxs("div", __assign({ className: "flex flex-col" }, { children: [_jsxs("div", __assign({ className: "flex justify-between items-center" }, { children: [_jsx("h1", __assign({ className: "text-base text-gray-600 font-semibold" }, { children: "Are you sure you want to reassign" })), _jsx(AptiveIcon, { icon: "x", className: "w-6 h-6 stroke-[#3D3D3D] fill-none cursor-pointer", onClick: onClose })] })), _jsxs("h1", __assign({ className: "text-base text-gray-600 font-semibold" }, { children: [name.split(' ')[0], "\u2019s remaining services?"] }))] })), _jsx(Button, __assign({ variant: "default", className: "mt-4", onClick: onReassign }, { children: "Yes, reassign" })), _jsx(Button, __assign({ variant: "dark", onClick: onClose }, { children: "No, don\u2019t reassign" }))] })));
};
export default ReassignConfirmModal;
